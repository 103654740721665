import React, { useState } from "react";

const RoleGroup = ({ title, roles, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const newCheckedState = e.target.checked;
    setIsChecked(newCheckedState);
    onChange(title, newCheckedState);
  };

  return (
    <div className="parentRole">
      <input
        type="checkbox"
        id={title}
        className="checkBox"
        name={title}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={title}>{title}</label>
      <div className="childRole">
        {roles.map((role) => (
          <div key={role}>
            <input
              type="checkbox"
              id={role}
              className="checkBox"
              name={role}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={role}>{role}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoleGroup;
