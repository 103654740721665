import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import NavBar from "../element/NavBar";
import Sidebar from "./Sidebar";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const ImportJobseekers = () => {
  const [loading, setLoading] = useState(false);
  const [jobSeekerData, setJobSeekerData] = useState({
    jobseeker_list: "",
  });
  const [errors, setErrors] = useState({
    jobseeker_list: "",
  });

  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState([]);

  const tokenKey = Cookies.get("tokenClient");
  const navigate = useNavigate();
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverSearchColor, setHoverSearchColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (jobSeekerData.jobseeker_list === "") {
        newErrors.jobseeker_list = "Upload is required";
        window.scrollTo(0, 0);
      }
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const uploadfile = {
          selectedFile: selectedFile,
          selectedFileName: selectedFileName,
        };

        setLoading(true);
        const response = await axios.post(
          BaseApi + "/users/import",
          uploadfile,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        setLoading(false);
        setJobSeekerData(response.data.response);
        // console.log(mailData);
        console.log("Jobseeker data received!");
      }
    } catch (error) {
      setLoading(false);
      console.log("Cannot get jobseeker data!");
    }
  };

  const handleFileUpload = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64);
    // setJobSeekerData({ ...jobSeekerData, base64 });
    setSelectedFile(base64);
    setSelectedFileName(e.target.files[0].name);
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      // getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />
      <div className="container importJobseeker validation">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5 CLPanelRight"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="d-flex PageHeader">
                  <img src="/Images/employerSide/icon5color.png" alt="" />
                  <h3 className="mx-2">Import Jobseekers</h3>
                </div>
                <form>
                  <div className="mb-5 mt-5">
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label htmlFor="formFile" className="form-label">
                        Import File
                      </label>
                      <input
                        className={`form-control ${
                          errors.jobseeker_list && "input-error"
                        }`}
                        type="file"
                        id="formFile"
                        lable="Image"
                        name="profile_image"
                        accept=".xls, .xlsx"
                        onChange={(e) => handleFileUpload(e)}
                      />
                      {errors.jobseeker_list && (
                        <div className="text-danger">
                          {errors.jobseeker_list}
                        </div>
                      )}
                      <div id="emailHelp" className="form-text">
                        Supported File Types: xls, xlsx. Download sample xls
                        file, put value under each column and do not add/remove
                        or change header in xls.
                      </div>
                    </div>
                  </div>
                  <div className="bottomButtons importJobseekersButtons">
                    <button
                      type="button"
                      className="btn btn-primary button1"
                      onClick={handleClick}
                      style={{
                        backgroundColor: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverSearchColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleSearchMouseEnter}
                      onMouseLeave={handleSearchMouseLeave}
                    >
                      UPLOAD
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary button2"
                      style={{
                        color: hoverUploadCVColor
                          ? primaryColor
                          : secondaryColor,
                        backgroundColor: "white",
                        border: hoverUploadCVColor
                          ? `2px solid ${primaryColor}`
                          : `2px solid ${secondaryColor}`,
                      }}
                      onMouseEnter={handleUploadCVMouseEnter}
                      onMouseLeave={handleUploadCVMouseLeave}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ImportJobseekers;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
